import { BuildingCostCenterCategory } from '@app/models/buildingCostCenter';
import { Building } from '@app/models/buildings';
import { CostCenterCategoryResponse } from '@app/models/costcenter';
import { useCreateBuildingMutation, useUpdateBuildingMutation } from '@app/services/buildingsApi';
import StyledSwitch from '@components/common/StyledSwitch';
import CostCenterSearch from '@components/costcenter/CostCenterSearch';
import exclamation from '@icons/exclamation.svg';
import { Button, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type AddBuildingDrawerProps = {
  handleClose: () => void;
  editing: boolean;
  selectedBuilding?: Building | null;
  handleDelete: (building: Building) => void;
  handleReOpen: (building: Building) => void;
  isClosed: boolean;
};

function findCostCenterByCategory(
  buildingCostCenters: Array<CostCenterCategoryResponse>,
  category: BuildingCostCenterCategory,
) {
  return (
    buildingCostCenters.find(
      (buildingCostCenter: CostCenterCategoryResponse) => buildingCostCenter.category === category,
    )?.costCenter || null
  );
}

function AddBuildingDrawer(props: AddBuildingDrawerProps) {
  const { handleClose, editing, selectedBuilding, handleDelete, isClosed, handleReOpen } = props;
  const { t } = useTranslation('buildings');

  const canEdit = editing && selectedBuilding;
  const [buildingAcronym, setBuildingAcronym] = useState(canEdit ? selectedBuilding?.acronym : '');
  const [buildingName, setBuildingName] = useState(canEdit ? selectedBuilding?.name : '');

  const [selectedInfraCostCenter, setSelectedInfraCostCenter] = useState(
    canEdit ? findCostCenterByCategory(selectedBuilding.buildingCostCenters, BuildingCostCenterCategory.INFRA) : null,
  );

  const [selectedSharingCostCenter, setSelectedSharingCostCenter] = useState(
    canEdit ? findCostCenterByCategory(selectedBuilding.buildingCostCenters, BuildingCostCenterCategory.SHARING) : null,
  );

  const [fixedWorkpoints, setFixedWorkpoints] = useState(canEdit ? selectedBuilding.fixedWorkPoints : false);
  const [workpoints, setWorkpoints] = useState(canEdit ? selectedBuilding.workPoints : (null as number | null));
  const [createBuilding] = useCreateBuildingMutation();
  const [updateBuilding] = useUpdateBuildingMutation();
  const title = editing ? t('Novo Prédio') : t('Editar Prédio');

  async function handleCreateBuilding() {
    const response = await createBuilding({
      name: buildingName,
      acronym: buildingAcronym,
      workPoints: workpoints || 0,
      fixedWorkPoints: fixedWorkpoints,
      buildingCostCenters: [
        {
          costCenterCode: selectedSharingCostCenter?.code as string,
          category: BuildingCostCenterCategory.SHARING,
        },
        {
          costCenterCode: selectedInfraCostCenter?.code as string,
          category: BuildingCostCenterCategory.INFRA,
        },
      ],
    });
    if ('data' in response) {
      toast.success(
        t('Prédio adicionado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      handleClose();
    }
  }

  async function handleEditBuilding() {
    const response = await updateBuilding({
      id: selectedBuilding?.id || 0,
      body: {
        name: buildingName,
        acronym: buildingAcronym,
        workPoints: workpoints || 0,
        fixedWorkPoints: fixedWorkpoints,
        active: selectedBuilding?.active || true,
        buildingCostCenters: [
          {
            costCenterCode: selectedSharingCostCenter?.code as string,
            category: BuildingCostCenterCategory.SHARING,
          },
          {
            costCenterCode: selectedInfraCostCenter?.code as string,
            category: BuildingCostCenterCategory.INFRA,
          },
        ],
      },
    });
    if ('data' in response) {
      toast.success(
        t('Prédio editado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      handleClose();
    }
  }

  function canAdd() {
    return (
      buildingAcronym.length === 3 && buildingName.length > 0 && (!fixedWorkpoints || Number.isInteger(workpoints))
    );
  }

  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', marginRight: '16px' }}>
          <Typography sx={{ marginTop: '24px' }} variant="h2">
            {t('Sigla do Prédio')}
          </Typography>
          <TextField
            inputProps={{ maxLength: 3 }}
            value={buildingAcronym}
            onChange={(e) => setBuildingAcronym(e.target.value)}
            sx={{ marginTop: '4px', width: '100%' }}
            size="small"
            id="outlined-basic"
            variant="outlined"
          />
          <Typography variant="subtitle1">{t('A sigla deve conter 3 letras')}</Typography>
        </div>
        <div style={{ width: '100%' }}>
          <Typography sx={{ marginTop: '24px' }} variant="h2">
            {t('Nome do Prédio')}
          </Typography>
          <TextField
            value={buildingName}
            onChange={(e) => setBuildingName(e.target.value)}
            sx={{ marginTop: '4px', width: '100%' }}
            size="small"
            id="outlined-basic"
            variant="outlined"
          />
        </div>
      </div>
      <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '24px' }} />
      <Typography sx={{ marginTop: '24px' }} variant="h3">
        {t('C.C de Correlatos')}
      </Typography>
      <Typography sx={{ marginTop: '4px' }} variant="subtitle2">
        {t('Associe um centro de custo para despesas principais')}
      </Typography>
      <CostCenterSearch selectedCostCenter={selectedSharingCostCenter} setCostCenter={setSelectedSharingCostCenter} />
      <Typography sx={{ marginTop: '24px' }} variant="h3">
        {t('C.C de Infra')}
      </Typography>
      <Typography sx={{ marginTop: '4px' }} variant="subtitle2">
        {t('Associe um centro de custo para despesas do CESAR')}
      </Typography>
      <CostCenterSearch selectedCostCenter={selectedInfraCostCenter} setCostCenter={setSelectedInfraCostCenter} />
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h2">
          {t('Possui pontos de trabalho fixos?')}
        </Typography>
        <Tooltip
          placement="top"
          title={t(
            'Este valor será utilizado como total de baias para o cálculo percentual dos projetos por Headcount.',
          )}
        >
          <img style={{ width: '16px', marginLeft: '8px' }} src={exclamation} alt={t('exclamação') as string} />
        </Tooltip>
      </div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <StyledSwitch
          checked={fixedWorkpoints}
          onChange={(e) => setFixedWorkpoints(e.target.checked)}
          sx={{ marginTop: '16px' }}
        />
        <Typography color="secondary" variant="body1" sx={{ marginLeft: '8px' }}>
          {fixedWorkpoints ? t('Sim') : t('Não')}
        </Typography>
      </div>
      <TextField
        required
        disabled={!fixedWorkpoints}
        sx={{ marginTop: '15px', width: '100%' }}
        size="small"
        type="number"
        value={workpoints}
        onChange={(e) => setWorkpoints(parseInt(e.target.value, 10))}
        id="outlined-basic"
        variant="outlined"
        placeholder={t('Digite uma quantidade...')}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        {canEdit &&
          (!isClosed ? (
            <Button variant="tertiary" style={{ marginRight: '24px' }} onClick={() => handleDelete(selectedBuilding)}>
              {t('Desabilitar Prédio')}
            </Button>
          ) : (
            <Button variant="tertiary" style={{ marginRight: '24px' }} onClick={() => handleReOpen(selectedBuilding)}>
              {t('Habilitar Prédio')}
            </Button>
          ))}

        <Button
          variant="secondary"
          style={{
            marginRight: '16px',
          }}
          onClick={() => handleClose()}
        >
          {t('Cancelar')}
        </Button>
        <Button
          variant="primary"
          disabled={!canAdd()}
          onClick={() => {
            if (canEdit) {
              handleEditBuilding();
            } else {
              handleCreateBuilding();
            }
          }}
        >
          {canEdit ? t('Salvar') : t('Adicionar')}
        </Button>
      </div>
    </>
  );
}

export default AddBuildingDrawer;
