import { CostCenterResponse } from '@/app/models/costcenter';
import { useCreateProjectMutation } from '@/app/services/projectApi';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AmountField from '../common/amountField';
import PercentField from '../common/percentField';
import CostCenterSearch from '../costcenter/CostCenterSearch';
import BuildingSelection from './BuildingSelection';

type AddProjectDrawerProps = {
  setOpen: (arg: boolean) => void;
  month: number;
  year: number;
};

const possibleTypes = [
  { id: 'HEADCOUNT', label: 'Headcount' },
  { id: 'FIXED_PERCENTAGE', label: 'Percentual Fixo' },
  { id: 'INFRA', label: 'Custo CESAR' },
  { id: 'NOT_SHAREABLE', label: 'Não Rateável' },
  { id: 'META', label: 'Meta' },
];

function AddProjectDrawer(props: AddProjectDrawerProps) {
  const { setOpen, year, month } = props;
  const { t } = useTranslation('projects');
  const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenterResponse | null>(null);
  const [buildingAcronym, setBuildingAcronym] = useState('');
  const [usedHeadcount, setUsedHeadcount] = useState(0);
  const [fixedPercentage, setFixedPercentage] = useState(0);
  const [goal, setgoal] = useState(null as unknown as number);
  const [type, setType] = useState('default');
  const [createProject] = useCreateProjectMutation();

  const handleCreate = async () => {
    const response = await createProject({
      costCenterCode: selectedCostCenter?.code || '',
      buildingAcronym,
      sharingType: type,
      usedHeadcount,
      fixedPercentage,
      goal,
      period: {
        month,
        year,
      },
    });
    if ('data' in response) {
      toast.success(
        t('Projeto criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setOpen(false);
    } else {
      toast.error(
        t('Erro ao criar projeto', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <>
      <Typography variant="h1">{t('Novo Projeto')}</Typography>

      <div style={{ width: '100%' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          {t('Projeto')}
        </Typography>
        <CostCenterSearch selectedCostCenter={null} setCostCenter={setSelectedCostCenter} />
      </div>
      <div style={{ width: '100%' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          {t('Prédio')}
        </Typography>
        <BuildingSelection buildingAcronym={buildingAcronym} setBuildingAcronym={setBuildingAcronym} />
      </div>
      <div style={{ width: '100%' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          {t('Tipo de Rateio')}
        </Typography>
        <Select
          sx={{ width: '100%', marginRight: '10px', fontSize: '13px', padding: 0, margin: 0 }}
          size="small"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <MenuItem disabled value="default">
            <em>{t('Selecione o tipo de rateio')}</em>
          </MenuItem>
          {possibleTypes.map((ty) => (
            <MenuItem key={ty.id} value={ty.id} sx={{ fontSize: '13px' }}>
              {ty.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '32px', marginBottom: '32px' }} />

      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <div style={{ width: '50%', marginRight: '16px' }}>
          <AmountField value={0} label="Headcount recebido" disable onChange={() => {}} />
        </div>
        <div style={{ width: '50%' }}>
          <AmountField
            value={usedHeadcount}
            label="Headcount considerado"
            disable={false}
            onChange={(value) => setUsedHeadcount(value)}
          />
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <div style={{ width: '50%', marginRight: '16px' }}>
          <PercentField
            value={fixedPercentage}
            label="Fixo (%)"
            disable={false}
            onChange={(value) => {
              setFixedPercentage(value);
            }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <AmountField label="Meta (R$)" value={goal} onChange={(e) => setgoal(e)} />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        <Button
          variant="secondary"
          style={{
            marginRight: '16px',
          }}
          onClick={() => setOpen(false)}
        >
          {t('Cancelar')}
        </Button>
        <Button
          disabled={
            !buildingAcronym ||
            !selectedCostCenter ||
            type === 'default' ||
            fixedPercentage > 100 ||
            fixedPercentage < 0
          }
          onClick={handleCreate}
          variant="primary"
        >
          {t('Adicionar')}
        </Button>
      </div>
    </>
  );
}

export default AddProjectDrawer;
