import { DateTime } from 'luxon';

const YEAR_FORMAT = 'yyyy';
const MONTH_FORMAT = 'MM';
const PERIOD_FORMAT = 'yyyy-MM';
const PERIOD_LABEL_FORMAT = 'MMMM/yyyy';
const DECIMAL_BASE = 10;
const DEFAULT_ZONE = 'America/Recife';
const DATETIME_FORMAT = "dd/LL/yyyy 'às' HH:mm'h'";
const NUMBER_OF_PERIOEDS_DEFAULT = 4;
export const DATE_ISO_FORMAT = 'yyyy-MM-dd';

/**
 * Extracts and returns the year as a number from a given period string, based on a predefined `PERIOD_FORMAT`.
 *
 * @param period - The period string to extract the year from. The string should conform to the structure
 *                          defined by `PERIOD_FORMAT` to be correctly parsed.
 * @returns The year extracted from the period, represented as a number.
 *
 * @example
 * monthFromPeriod('2024-03');
 * Returns: 2024
 */
export function yearFromPeriod(period: string, dateFormat = PERIOD_FORMAT): number {
  return parseInt(DateTime.fromFormat(period, dateFormat).toFormat(YEAR_FORMAT), DECIMAL_BASE);
}

/**
 * Extracts and returns the month as a number from a given period string, based on a predefined `PERIOD_FORMAT`.
 *
 * @param period - The period string to extract the month from. The string should conform to the structure
 *                          defined by `PERIOD_FORMAT` to be correctly parsed.
 * @returns The month extracted from the period, represented as a number. The month is based on the formatting
 *                   defined by `MONTH_FORMAT` and converted from a string to a number using `DECIMAL_BASE` for parsing.
 *                   January is represented by 1, February by 2, and so on, up to December, which is represented by 12.
 *
 * @example
 * monthFromPeriod('2024-03');
 * Returns: 3 (representing March as the third month of the year)
 */
export function monthFromPeriod(period: string, dateFormat = PERIOD_FORMAT): number {
  return parseInt(DateTime.fromFormat(period, dateFormat).toFormat(MONTH_FORMAT), DECIMAL_BASE);
}

/**
 * Formats a given period string into a localized period label using predefined formats.
 * The input `period` is expected to match the `PERIOD_FORMAT`, which defines how the period is structured.
 * This function then localizes and formats the period according to `PERIOD_LABEL_FORMAT`, taking into account
 * the specified `language` for localization.
 *
 * @param period - The period string to be formatted, expected to conform to `PERIOD_FORMAT`.
 * @param language - The IETF language tag (e.g., 'en-US', 'pt-BR') to use for localizing the period label.
 * @returns A string representing the localized and formatted period label, according to `PERIOD_LABEL_FORMAT`.
 *
 * @example
 * periodLabel('2024-03', 'pt-BR');
 * Returns: 'março/2024'
 */
export function periodLabel(period: string, language: string, dateFormat = PERIOD_FORMAT): string {
  return DateTime.fromFormat(period, dateFormat).setLocale(language).toFormat(PERIOD_LABEL_FORMAT);
}

/**
 * Converts an ISO date and time string to a formatted string using a predefined `DATETIME_FORMAT`.
 *
 * @param dateTime - An ISO 8601 date and time string.
 * @returns The date and time formatted as a string according to `DATETIME_FORMAT`.
 *
 * @example
 * datetimeLabelFormated('2024-03-18T15:47:45');
 * // Returns: '18/03/2024 às 15:47h'
 */
export function datetimeLabelFormated(dateTime: string): string {
  return DateTime.fromISO(dateTime, { zone: DEFAULT_ZONE }).toFormat(DATETIME_FORMAT);
}

/**
 * Retrieves the current period formatted as a string according to the predefined `PERIOD_FORMAT`.
 *
 * @returns The current period formatted as `PERIOD_FORMAT`.
 *
 * @example
 * // Assuming the current date is 25/03/2024
 * getCurrentPeriod());
 * // Returns: '2024-03'
 */
export function getCurrentPeriod(dataFormat: string = PERIOD_FORMAT): string {
  return DateTime.now().toFormat(dataFormat);
}

/**
 * Generates a list of formatted date strings, each representing a period in the past relative to the `currentPeriod`.
 * The number of periods generated is determined by `numberOfPeriods`.The function uses `PERIOD_FORMAT` to parse the dates.
 *
 * @param currentPeriod - The starting period in `PERIOD_FORMAT` format from which to calculate previous periods.
 * @param numberOfPeriods - The number of previous periods to generate. If `undefined` or not greater than 0,
 *                          `NUMBER_OF_PERIODS_DEFAULT` is used as the default number of periods.
 * @returns An array of strings, each representing the  period in `PERIOD_FORMAT` for the months preceding
 *                          the `currentPeriod`. The array starts with the `currentPeriod` and moves backwards in time.
 *
 * @example
 * getPreviousPeriodsList('2024-03', 3);
 * // Returns ['2024-03', '2024-02', '2024-01']
 */
export function getPreviousPeriodsList(
  currentPeriod: string,
  numberOfPeriods: number = NUMBER_OF_PERIOEDS_DEFAULT,
  dataFormat: string = PERIOD_FORMAT,
): Array<string> {
  const count = numberOfPeriods && numberOfPeriods > 0 ? numberOfPeriods : NUMBER_OF_PERIOEDS_DEFAULT;
  const currentDate = DateTime.fromFormat(currentPeriod, dataFormat);
  return [...Array(count).keys()].map((i) => currentDate.minus({ months: i }).toFormat(dataFormat));
}

export function dateRange(start: string, end: string, dataFormat: string = PERIOD_FORMAT) {
  const startDate = DateTime.fromFormat(start, dataFormat);
  const endDate = DateTime.fromFormat(end, dataFormat);
  const numberOfPeriods = Math.abs(startDate.diff(endDate, 'months').months);
  return getPreviousPeriodsList(start, numberOfPeriods, dataFormat);
}
