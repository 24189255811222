import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React from 'react';

const columns: GridColDef[] = [
  {
    field: 'acronym',
    headerName: 'Sigla',
    width: 100,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'name',
    headerName: 'Prédio',
    width: 100,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'category',
    headerName: 'Filial',
    width: 200,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: (params) => params.row.buildingCostCenters[0].costCenter.branch.name,
  },
];

interface BuildingLinksGridProps {
  selectedBuildingIds: Array<number>;
  onChangeBuildingIds: (newValues: Array<number>) => void;
}

export default function BuildingLinksGrid(props: BuildingLinksGridProps) {
  const { selectedBuildingIds, onChangeBuildingIds } = props;
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>(selectedBuildingIds);

  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={buildings || []}
        columns={columns}
        hideFooter
        hideFooterSelectedRowCount
        checkboxSelection
        disableRowSelectionOnClick
        sx={{
          border: 'none',
          '& .MuiCheckbox-root.Mui-checked': {
            color: '#ff7b00',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 900,
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#FFF4E9',
            '&:hover': {
              backgroundColor: '#FFF4E9',
            },
          },
        }}
        onRowSelectionModelChange={(newSelectionModel) => {
          onChangeBuildingIds(newSelectionModel as Array<number>);
          setSelectionModel(newSelectionModel);
        }}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
