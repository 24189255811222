import Navbar from '@/components/common/Navbar';
import Sidebar from '@/components/sidebar/Sidebar';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <Box display="flex" width="100%" height="100%">
      <Box flexGrow={1}>
        <Navbar />
        <Box sx={{ display: 'flex', gap: '1.5rem', paddingTop: '1.0rem' }}>
          <Sidebar />
          <Outlet />
        </Box>
        <Box />
      </Box>
    </Box>
  );
}
export default Layout;
