import { SharedExpensesProcessingStatus } from '@app/models/accountingExpense';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface StatusInfo {
  color: string;
  text: string;
  date: string;
}

interface StatusDisplayProps {
  status: string;
  lastModifiedDate: string;
}

const statusFactory = (type: string, date: string): StatusInfo => {
  const formattedDate = date ? DateTime.fromISO(date).toFormat('dd/LL/yyyy H:mm:ss') : '';
  switch (type) {
    case SharedExpensesProcessingStatus.NO_EXPENSES:
      return { color: '#FFFFFF', text: '---', date: formattedDate };
    case SharedExpensesProcessingStatus.RETRIEVING_EXPENSES:
      return { color: '#CCCCCC', text: 'Buscando Despesas', date: formattedDate };
    case SharedExpensesProcessingStatus.PENDING:
      return { color: '#FFC107', text: 'Pendente', date: formattedDate };
    case SharedExpensesProcessingStatus.PROCESSING:
      return { color: '#CCCCCC', text: 'Processando', date: formattedDate };
    case SharedExpensesProcessingStatus.PROCESSED:
      return { color: '#28A745', text: 'Processado', date: formattedDate };
    case SharedExpensesProcessingStatus.ERROR:
      return { color: 'red', text: 'Erro no processamento', date: formattedDate };
    default:
      return { color: '#CCCCCC', text: '???', date: formattedDate };
  }
};

function StatusDisplay(props: StatusDisplayProps) {
  const { status, lastModifiedDate } = props;
  const { t } = useTranslation('process');

  const statusInfo = statusFactory(status, lastModifiedDate);

  return (
    <Box display="flex" alignItems="flex-start" flexWrap="wrap">
      {status !== SharedExpensesProcessingStatus.NO_EXPENSES && (
        <div
          style={{
            width: '8px',
            height: '8px',
            alignSelf: 'center',
            background: statusInfo.color,
            borderRadius: '25px',
            margin: 5,
          }}
        />
      )}
      <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 600 }}>{t(statusInfo.text)}</Typography>
      <Typography sx={{ marginLeft: 2, fontSize: '13px', color: '#888888' }}>{statusInfo.date}</Typography>
    </Box>
  );
}

export default StatusDisplay;
