import Role from '@constants/roles';

enum Page {
  ROOT = 'ROOT',
  LOGIN = 'LOGIN',
  BUILDINGS = 'BUILDINGS',
  EXPENSES = 'EXPENSES',
  PROJECTS = 'PROJECTS',
  PROCESS = 'PROCESS',
  PERMISSIONS = 'PERMISSIONS',
  DASHBOARDS_GOALS = 'DASHBOARDS_GOALS',
  DASHBOARDS_SHARED_EXPENSES = 'DASHBOARDS_SHARED_EXPENSES',
}

/**
 * Since every page is accessed only if the user owns certain roles,
 * we must define a set of Pages and the roles of every page
 */

export const PAGE_ROLES: Record<Page, Array<string>> = {
  ROOT: [Role.SUPER, Role.ADMIN, Role.CONTROLLERSHIP, Role.PMO, Role.SHARED],
  LOGIN: [Role.SUPER, Role.ADMIN, Role.CONTROLLERSHIP, Role.PMO, Role.SHARED],
  BUILDINGS: [Role.CONTROLLERSHIP, Role.SHARED],
  EXPENSES: [Role.CONTROLLERSHIP],
  PROJECTS: [Role.PMO],
  PROCESS: [Role.CONTROLLERSHIP],
  DASHBOARDS_GOALS: [Role.PMO],
  DASHBOARDS_SHARED_EXPENSES: [Role.CONTROLLERSHIP],
  PERMISSIONS: [Role.SUPER, Role.ADMIN],
};

export const PAGE_PATHS: Record<Page, string> = {
  ROOT: '/',
  LOGIN: '/login',
  BUILDINGS: '/buildings',
  EXPENSES: '/expenses',
  PROJECTS: '/projects',
  PROCESS: '/process',
  DASHBOARDS_GOALS: '/dashboards-goals',
  DASHBOARDS_SHARED_EXPENSES: '/dashboards-shared-expenses',
  PERMISSIONS: '/permissions',
};

export default Page;
