import { PAGE_PATHS, PAGE_ROLES } from '@constants/pages';

export interface SubMenu {
  id: number;
  label: string;
  navigate: string;
  roles: Array<string>;
}

export interface Menu {
  title: string;
  subMenus: Array<SubMenu>;
}

const MENU: Array<Menu> = [
  {
    title: 'Configurações',
    subMenus: [
      { id: 1, label: 'Prédios', navigate: PAGE_PATHS.BUILDINGS, roles: PAGE_ROLES.BUILDINGS },
      { id: 2, label: 'Despesas', navigate: PAGE_PATHS.EXPENSES, roles: PAGE_ROLES.EXPENSES },
      { id: 3, label: 'Projetos', navigate: PAGE_PATHS.PROJECTS, roles: PAGE_ROLES.PROJECTS },
    ],
  },
  {
    title: 'Rateio',
    subMenus: [{ id: 4, label: 'Processar', navigate: PAGE_PATHS.PROCESS, roles: PAGE_ROLES.PROCESS }],
  },
  {
    title: 'Dashboards',
    subMenus: [
      { id: 5, label: 'Total Metas', navigate: PAGE_PATHS.DASHBOARDS_GOALS, roles: PAGE_ROLES.DASHBOARDS_GOALS },
      {
        id: 6,
        label: 'Total Lançamentos',
        navigate: PAGE_PATHS.DASHBOARDS_SHARED_EXPENSES,
        roles: PAGE_ROLES.DASHBOARDS_SHARED_EXPENSES,
      },
    ],
  },
  {
    title: 'Administração',
    subMenus: [
      { id: 7, label: 'Permissões do Produto', navigate: PAGE_PATHS.PERMISSIONS, roles: PAGE_ROLES.PERMISSIONS },
    ],
  },
];

export default MENU;
