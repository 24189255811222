import { useDeleteAccountingExpenseMutation, useGetAccountingExpensesQuery } from '@/app/services/accountingExpenseApi';
import TextButton from '@/components/buildings/TextButton';
import LateralDrawer from '@/components/common/LateralDrawer';
import CancelOrConfirmDialog from '@/components/common/dialogs/CancelOrConfirmDialog';
import SearchComponent from '@/components/common/search';
import ExpenseNewEditScreen from '@/components/expenses/NewEditScreen';
import ExpenseTable from '@/components/expenses/table';
import AccountingExpense from '@app/models/accountingExpense';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ON_CHANGE_DELAY = 1000; // 1 second

interface ExpensesProps {
  selectedPeriod: string;
}

function Expenses(props: ExpensesProps) {
  const { selectedPeriod } = props;
  const { t } = useTranslation('expense');

  const [drawerStatus, setDrawerStatus] = useState(false);
  const [filter, setFilter] = useState('');
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<AccountingExpense | null>(null);
  const [openExcludeDialog, setOpenExcludeDialog] = useState(false);

  const { data: accountingExpenseList } = useGetAccountingExpensesQuery(query);

  const [deleteAccountingExpense] = useDeleteAccountingExpenseMutation();

  useEffect(() => {
    const timeOutId = setTimeout(() => setQuery(filter), ON_CHANGE_DELAY);
    return () => clearTimeout(timeOutId);
  }, [filter]);

  const handleDelete = async (id: number) => {
    if (id) {
      const response = await deleteAccountingExpense(id);
      if ('data' in response) {
        toast.success(
          t('Despesa removida com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 3, mr: 3 }}>
      <Typography variant="h1" sx={{ marginBottom: 2 }}>
        {t('Despesas')}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <SearchComponent
          placeholder={t('Pesquisar despesa pelo nome ou código')}
          onFilterChange={(value: string) => setFilter(value)}
        />
        <TextButton
          style={{ marginLeft: 'auto' }}
          text={`+ ${t('Nova Despesa')}`}
          onClick={() => {
            setSelectedItem(null);
            setDrawerStatus(true);
          }}
        />
      </Box>
      <ExpenseTable
        items={accountingExpenseList || []}
        onRowSelect={(item) => {
          setSelectedItem(item);
          setDrawerStatus(true);
        }}
        onDelete={(item: AccountingExpense) => {
          setOpenExcludeDialog(true);
          setSelectedItem(item);
        }}
      />
      <LateralDrawer open={drawerStatus} setOpen={setDrawerStatus} position="right">
        <ExpenseNewEditScreen expense={selectedItem} setDrawerOpen={setDrawerStatus} />
      </LateralDrawer>
      <CancelOrConfirmDialog
        title={t('Excluir Despesa')}
        contentText={t(`Tem certeza que deseja excluir esta Despesa?`)}
        open={openExcludeDialog}
        onClose={() => setOpenExcludeDialog(false)}
        onConfirm={() => {
          setOpenExcludeDialog(false);
          if (selectedItem) {
            handleDelete(selectedItem.id);
          }
        }}
        cancelText={t('Não')}
        confirmText={String(t('Sim'))}
      />
    </Box>
  );
}
export default Expenses;
