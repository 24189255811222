import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { CSSProperties } from 'react';

type CallbackFunction = (v: boolean) => void;
type CheckOptionProps = {
  text?: string;
  setChecked: CallbackFunction;
  checked?: boolean;
  style?: CSSProperties;
};

function CheckOption(props: CheckOptionProps) {
  const { text, checked, setChecked, style } = props;
  return (
    <FormControlLabel
      sx={{ whiteSpace: 'nowrap', fontSize: '12px', ...style }}
      control={
        <Checkbox
          color="secondary"
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />
      }
      label={<Typography sx={{ fontSize: '12px' }}>{text}</Typography>}
    />
  );
}

export default CheckOption;
