import { Building } from '@/app/models/buildings';
import {
  useDisableBuildingMutation,
  useGetBuildingsQuery,
  useUpdateBuildingMutation,
} from '@/app/services/buildingsApi';
import AddBuildingDrawer from '@/components/buildings/AddBuildingDrawer';
import TextButton from '@/components/buildings/TextButton';
import BuildingTable from '@/components/buildings/table';
import CheckOption from '@/components/common/CheckOption';
import LateralDrawer from '@/components/common/LateralDrawer';
import SearchField from '@/components/common/SearchField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface BuildingsProps {
  selectedPeriod: string;
}

function Buildings(props: BuildingsProps) {
  const { selectedPeriod } = props;

  const { t } = useTranslation('buildings');
  const [buildingSearch, setBuildingSearch] = useState('');
  const [showDisabledBuildings, setShowDisabledBuildings] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: buildingSearch, active: !showDisabledBuildings });
  const [disableBuilding] = useDisableBuildingMutation();
  const [editing, setEditing] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null as Building | null);

  const [updateBuilding] = useUpdateBuildingMutation();

  function handleClose() {
    setShowCreateModal(false);
    setEditing(false);
    setSelectedBuilding(null);
  }

  async function handleDelete(building: Building) {
    const response = await disableBuilding(building.id);

    if ('data' in response) {
      toast.success(
        t('Prédio desabilitado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    handleClose();
  }

  async function handleReOpen(building: Building) {
    const response = await updateBuilding({
      id: building.id,
      body: {
        name: building.name,
        acronym: building.acronym,
        fixedWorkPoints: building.fixedWorkPoints,
        workPoints: building.workPoints,
        buildingCostCenters: building.buildingCostCenters.map((b) => ({
          costCenterCode: b.costCenter.code,
          category: b.category,
        })),
        active: true,
      },
    });

    if ('data' in response) {
      toast.success(
        t('Prédio desabilitado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    handleClose();
  }

  function handleOpenEdit(building: Building) {
    setSelectedBuilding(building);
    setEditing(true);
    setShowCreateModal(true);
  }

  return (
    <div style={{ width: '100%', marginTop: '32px' }}>
      <Typography variant="h1">{t('Prédios')}</Typography>

      <div style={{ width: '100%', display: 'flex', marginTop: '24px', marginBottom: '16px', alignItems: 'center' }}>
        <SearchField
          searchTerm={buildingSearch}
          setSearchTerm={setBuildingSearch}
          placeholder={t('Pesquisar pela sigla ou nome do prédio')}
        />
        <CheckOption
          style={{ marginLeft: '24px' }}
          checked={showDisabledBuildings}
          setChecked={setShowDisabledBuildings}
          text={t('Exibir apenas prédios desabilitados')}
        />
        <TextButton
          style={{ marginLeft: 'auto' }}
          text={t('+ Novo Prédio')}
          onClick={() => {
            setEditing(false);
            setShowCreateModal(true);
          }}
        />
      </div>

      <BuildingTable
        onRowSelect={handleOpenEdit}
        onDelete={handleDelete}
        onReOpen={handleReOpen}
        items={buildings || []}
        isClosed={showDisabledBuildings}
      />
      <LateralDrawer open={showCreateModal} setOpen={setShowCreateModal} position="right">
        <AddBuildingDrawer
          handleClose={handleClose}
          editing={editing}
          selectedBuilding={selectedBuilding}
          handleDelete={handleDelete}
          handleReOpen={handleReOpen}
          isClosed={showDisabledBuildings}
        />
      </LateralDrawer>
    </div>
  );
}
export default Buildings;
