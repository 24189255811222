import SquareIcon from '@mui/icons-material/Square';
import { BarCustomLayerProps, BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar';

import { ResponseTotalGoals } from '@/app/services/dashboardApi';
import { DATE_ISO_FORMAT, periodLabel } from '@/app/utils/dateUtils';
import { GroupModeTypes } from '@/components/dashboards/GroupedOrStackedToggle';
import { Box, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const formatter = new Intl.NumberFormat('pt-BR');
const formatterMonetaryValue = (value: any) => `R$ ${formatter.format(value)}`;

function BarTotalsLayer({ bars, xScale, yScale }: BarCustomLayerProps<BarDatum>) {
  const labelOffset = 10;
  const labelFontSize = 12;
  if (bars.length === 0) return null;
  const totals: { [id: string | number]: number } = {};
  const bandwidth = bars[0].width;
  bars.forEach((bar) => {
    const { indexValue, value } = bar.data;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      const numberValue = value || 0;
      totals[indexValue] += numberValue;
    }
  });

  return (Object.keys(totals) as any as number[]).map((indexValue) => {
    const x = xScale(indexValue) + bandwidth / 2;
    const y = yScale(totals[indexValue]) - labelOffset;
    return (
      <text key={`total.${indexValue}`} x={x} y={y} textAnchor="middle" fontSize={labelFontSize}>
        {`R$ ${formatter.format(totals[indexValue])}`}
      </text>
    );
  });
}

function BarTooltip({ data, value, color, label }: BarTooltipProps<any>) {
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Box display="flex" flexDirection="row" gap={1}>
        Prédio: <SquareIcon htmlColor={color} />
        <Typography fontWeight={600}>{label.substring(0, 3)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        Total metas projetos:
        <Typography fontWeight={600}>{formatterMonetaryValue(value)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        Período:
        <Typography fontWeight={600}>{periodLabel(data.period, 'pt-BR', DATE_ISO_FORMAT)}</Typography>
      </Box>
    </Paper>
  );
}

const groupedLayers = ['grid', 'axes', 'markers', 'bars', 'legends', 'annotations'];
const stackedLayers = [...groupedLayers, BarTotalsLayer];

interface TotalGoalsChartProps {
  data: Array<ResponseTotalGoals>;
  selectedBuildings: Array<string>;
  selectedPeriods: Array<string>;
  groupMode: GroupModeTypes;
}

function TotalGoalsChart({ data, selectedBuildings, selectedPeriods, groupMode }: TotalGoalsChartProps) {
  const [layers, setLaylers] = useState<Array<any>>(stackedLayers);

  const { i18n, t } = useTranslation('dashboards');

  const result = data
    ? data.map((x: ResponseTotalGoals) => ({
        ...x.data,
        period: x.period,
      }))
    : [];

  useEffect(() => {
    if (selectedBuildings?.length === 1 || groupMode === 'grouped') {
      setLaylers(groupedLayers);
    } else {
      setLaylers(stackedLayers);
    }
  }, [selectedBuildings, groupMode]);

  return (
    <ResponsiveBar
      data={result.filter((item) => selectedPeriods.includes(item?.period))}
      keys={selectedBuildings}
      indexBy="period"
      groupMode={groupMode}
      margin={{ top: 40, right: 130, bottom: 50, left: 100 }}
      valueFormat={formatterMonetaryValue}
      labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      labelSkipWidth={80}
      colors={{ scheme: 'nivo' }}
      tooltip={BarTooltip}
      animate
      enableLabel={false}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Total meta dos projetos',
        legendPosition: 'middle',
        legendOffset: -80,
        truncateTickAt: 0,
        format: formatterMonetaryValue,
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Período',
        legendPosition: 'middle',
        legendOffset: 40,
        truncateTickAt: 0,
        format: (value) => periodLabel(value, i18n.language, DATE_ISO_FORMAT),
      }}
      layers={layers}
    />
  );
}

export default TotalGoalsChart;
