import { FormControl, InputBaseComponentProps, OutlinedInput, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

interface PercentMaskProps extends InputBaseComponentProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const PercentMask = React.forwardRef<HTMLInputElement, PercentMaskProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      mask={Number}
      radix=","
      thousandsSeparator="."
      padFractionalZeros
      normalizeZeros
      min={0}
      max={100}
      scale={2}
      placeholder="0,00"
      inputRef={ref}
      unmask
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>);
      }}
      overwrite
    />
  );
});

PercentMask.displayName = 'TextMaskCustom';

interface PercentFieldProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  sx?: SxProps<Theme>;
  disable?: boolean;
}

export default function PercentField(props: PercentFieldProps) {
  const { label, value, onChange, sx, disable } = props;

  return (
    <FormControl>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        disabled={disable}
        sx={{
          ...sx,
          height: 35,
        }}
        value={value ? String(value) : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value ? Number(event.target.value) : 0);
        }}
        inputComponent={PercentMask}
        inputProps={{ 'aria-label': label }}
      />
    </FormControl>
  );
}
