import AccountingExpense, { NewOrUpdateAccountingExpense } from '@/app/models/accountingExpense';
import {
  useCreateAccountingExpenseMutation,
  useUpdateAccountingExpenseMutation,
} from '@/app/services/accountingExpenseApi';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import BuildingLinksGrid from './BuildingLinks';
import AccountingExpenseCodeField from './CodeField';
import AccountingExpenseDescriptionField from './DescriptionField';

interface ExpenseNewEditScreenProps {
  expense: AccountingExpense | null;
  setDrawerOpen: (open: boolean) => void;
}

export default function ExpenseNewEditScreen(props: ExpenseNewEditScreenProps) {
  const { expense, setDrawerOpen } = props;
  const { t } = useTranslation('expense');

  const [code, setCode] = useState(expense ? expense.code : '');
  const [description, setDescription] = useState(expense ? expense.description : '');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [buildingIds, setBuildingIds] = useState(expense ? expense.buildings.map((building) => building.id) : []);

  const [createAccountingExpense] = useCreateAccountingExpenseMutation();
  const [updateAccountingExpense] = useUpdateAccountingExpenseMutation();

  useEffect(() => {
    const enabled = code && description;
    setSaveDisabled(!enabled);
  }, [code, description]);

  const handleSave = async () => {
    const newOrUpdateAccountingExpense: NewOrUpdateAccountingExpense = {
      id: expense ? expense.id : null,
      code,
      description,
      buildingIds,
    };

    const execute = expense ? updateAccountingExpense : createAccountingExpense;

    const response = await execute(newOrUpdateAccountingExpense);
    if ('data' in response) {
      toast.success(
        t('Conta contábil salva com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setDrawerOpen(false);
    }
  };

  return (
    <>
      <Typography variant="h1">{!expense ? t('Nova Despesa') : t('Editar Despesa')}</Typography>
      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <AccountingExpenseCodeField
              label={t('Código')}
              value={code}
              onChange={(newValue: string) => setCode(newValue)}
            />
          </Grid>
          <Grid item xs={7}>
            <AccountingExpenseDescriptionField
              label={t('Nome da Despesa')}
              value={description}
              onChange={(newValue: string) => setDescription(newValue)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider sx={{ marginY: 2 }} />

      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        <Typography variant="h2">{t('Prédios associados')}</Typography>
        <Typography sx={{ color: '#888888' }}>
          {t('Selecione os prédios que estarão associados a esta despesa')}
        </Typography>
      </Box>

      <BuildingLinksGrid
        selectedBuildingIds={buildingIds}
        onChangeBuildingIds={(newValue) => setBuildingIds(newValue)}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        <Button
          style={{
            marginRight: '16px',
          }}
          variant="secondary"
          onClick={() => setDrawerOpen(false)}
        >
          {t('Cancelar')}
        </Button>
        <Button variant="primary" onClick={() => handleSave()} disabled={saveDisabled}>
          {t('Adicionar')}
        </Button>
      </div>
    </>
  );
}
