import { CostCenterResponse } from '@app/models/costcenter';
import api from '@services/api';

/**
 * <p>
 * API endpoints that covers all CostCenter CRUD calls
 */
export const costcenterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    costCenters: builder.query<CostCenterResponse[], string>({
      query: (searchTerm) => `/accounting-expense-sharing/cost-centers/search?searchTerm=${searchTerm}`,
      transformResponse: (resp: CostCenterResponse[]) =>
        resp.sort((a: CostCenterResponse, b: CostCenterResponse) => b.id - a.id),
      providesTags: ['CostCenter'],
    }),
  }),
});

export const { useCostCentersQuery } = costcenterApi;
