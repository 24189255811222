import BarChartIcon from '@mui/icons-material/BarChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export type GroupModeTypes = 'stacked' | 'grouped' | undefined;

interface GroupedOrStackedToggleProps {
  fieldLabel?: string;
  groupMode: string | undefined;
  setGroupMode: (value: GroupModeTypes) => void;
}

function GroupedOrStackedToggle({ fieldLabel, groupMode, setGroupMode }: GroupedOrStackedToggleProps) {
  const handleGroupMode = (event: React.MouseEvent<HTMLElement>, value: GroupModeTypes | undefined) => {
    if (value !== null) {
      setGroupMode(value);
    }
  };
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      {fieldLabel && (
        <Typography variant="h3" color="text.secondary">
          {fieldLabel}
        </Typography>
      )}
      <ToggleButtonGroup
        sx={{ height: 32 }}
        value={groupMode}
        exclusive
        onChange={handleGroupMode}
        aria-label="group mode"
      >
        <ToggleButton value="grouped" aria-label="grouped chart">
          <Tooltip title="Agrupado">
            <BarChartIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="stacked" aria-label="stacked chart">
          <Tooltip title="Empilhado">
            <StackedBarChartIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  );
}

export default GroupedOrStackedToggle;
