import { CostCenter } from '@/app/models/costcenter';
import { SharedAccountingExpense } from '@/app/models/sharedAccountingExpense';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import { useGetSharedAccountingExpensesQuery } from '@/app/services/sharedAccountingExpenseApi';
import { getPreviousPeriodsList, monthFromPeriod, periodLabel, yearFromPeriod } from '@/app/utils/dateUtils';
import BuildingProcess from '@/components/process/BuildingProcess';
import { Box, CircularProgress, FormControl, MenuItem, Modal, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const POLLING_INTERVAL = 5000; // 5 seconds
const CONFIRMATION_WAITING_TIME = POLLING_INTERVAL * 3; // waiting 3 times the polling interval

function displayCostCenter(costCenter: CostCenter | undefined) {
  let text = '';
  if (costCenter) {
    text = `${costCenter?.code} - ${costCenter?.name} (${costCenter?.branch.name})`;
  }
  return text;
}

interface ProcessProps {
  currentPeriod: string;
  numberOfPeriods?: number;
}

function Process(props: ProcessProps) {
  const { currentPeriod, numberOfPeriods } = props;
  const { i18n, t } = useTranslation('process');
  const [selectedPeriod, setSelectedPeriod] = useState(currentPeriod);
  const [selectedBuilding, setSelectedBuilding] = useState('undef');
  const [shownBuilding, setShownBuilding] = useState(-1);
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [pollingIntervalProcessing, setPollingIntervalProcessing] = useState(0);
  const { data: buildings } = useGetBuildingsQuery({
    searchTerm: '',
    active: true,
  });

  const { data: buildingSharedExpenses } = useGetSharedAccountingExpensesQuery(
    {
      year: yearFromPeriod(selectedPeriod),
      month: monthFromPeriod(selectedPeriod),
      buildingAcronym: selectedBuilding !== 'undef' ? selectedBuilding : undefined,
    },
    {
      pollingInterval: pollingIntervalProcessing,
    },
  );

  useEffect(() => {
    if (loadingProcessing) {
      setPollingIntervalProcessing(POLLING_INTERVAL);
      const timer = setTimeout(() => {
        toast.error('Foram realizado lançamentos, mas não houve resposta.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setPollingIntervalProcessing(0);
        setLoadingProcessing(false);
      }, CONFIRMATION_WAITING_TIME);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [loadingProcessing]);

  useEffect(() => {
    const processingList = buildingSharedExpenses?.filter(
      (sharedAccountingExpense: SharedAccountingExpense) => sharedAccountingExpense?.status === 'PROCESSING',
    );
    if (processingList && processingList?.length === 0 && loadingProcessing) {
      setLoadingProcessing(false);
      setPollingIntervalProcessing(0);
      toast.success('Os lançamentos foram processados.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [buildingSharedExpenses, loadingProcessing]);

  return (
    <div style={{ width: '100%', marginTop: '32px' }}>
      <Typography variant="h1">{t('Processar')}</Typography>
      <Typography variant="subtitle1" sx={{ marginTop: '8px' }}>
        {t('Consulte e processe os custos dos prédios por período')}
      </Typography>
      <div style={{ display: 'flex' }}>
        <div>
          <Modal
            open={loadingProcessing}
            sx={{
              display: 'flex',
              p: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: (theme) => theme.shadows[5],
                p: 4,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={50} color="secondary" />
                <div style={{ fontSize: '20px', marginBottom: '20px' }}>{t('Processando lançamentos...')}</div>
              </div>
            </Box>
          </Modal>

          <Typography variant="h2" sx={{ marginTop: '24px' }}>
            {t('Prédio')}
          </Typography>
          <FormControl sx={{ marginTop: 1, minWidth: 120 }}>
            <Select
              size="small"
              id="demo-simple-select"
              value={selectedBuilding}
              onChange={(e) => setSelectedBuilding(e.target.value)}
            >
              <MenuItem key={333} value="undef">
                Todos
              </MenuItem>
              {buildings?.map((i) => (
                <MenuItem key={i.id} value={i.acronym}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginLeft: '24px' }}>
          <Typography variant="h2" sx={{ marginTop: '24px' }}>
            {t('Período')}
          </Typography>
          <FormControl sx={{ marginTop: 1, minWidth: 120 }}>
            <Select
              size="small"
              id="demo-simple-select"
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              {getPreviousPeriodsList(currentPeriod, numberOfPeriods).map((period) => (
                <MenuItem key={period} value={period}>
                  {periodLabel(period, i18n.language)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={{ border: '1px solid #CCCCCC', marginRight: '24px', marginTop: '32px' }} />
      <div style={{ marginTop: '32px', marginRight: '24px' }}>
        {buildingSharedExpenses?.map((sharedExpense: SharedAccountingExpense) => {
          const expensesCostCenter = sharedExpense.building.buildingCostCenters.find(
            (i) => i.category === 'SHARING',
          )?.costCenter;
          const infraCostCenter = sharedExpense.building.buildingCostCenters.find(
            (i) => i.category === 'INFRA',
          )?.costCenter;
          return (
            <BuildingProcess
              key={sharedExpense.building.id}
              building={sharedExpense.building}
              period={selectedPeriod}
              month={monthFromPeriod(selectedPeriod)}
              year={yearFromPeriod(selectedPeriod)}
              expensesCostCenter={displayCostCenter(expensesCostCenter)}
              infraCostCenter={displayCostCenter(infraCostCenter)}
              status={sharedExpense.status}
              lastModifiedDate={sharedExpense?.lastModifiedDate}
              showExpenses={shownBuilding === sharedExpense.building.id}
              setShowExpenses={(s) => {
                if (s) {
                  setShownBuilding(sharedExpense.building.id);
                } else {
                  setShownBuilding(-1);
                }
              }}
              setPollingIntervalProcessing={setPollingIntervalProcessing}
              setLoadingProcessing={setLoadingProcessing}
              processedFile={sharedExpense.processedFile}
            />
          );
        })}
      </div>
    </div>
  );
}
export default Process;
