import { IncomingAccountingExpense } from '@app/models/accountingExpense';
import api from '@services/api';

interface IncomingAccountingExpenseRequest {
  buildingAcronym: string;
  month: number;
  year: number;
}

interface FetchIncomingAccountingExpenseRequest {
  buildingAcronym: string;
  period: string;
}

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIncomingAccountingExpense: builder.query<Array<IncomingAccountingExpense>, IncomingAccountingExpenseRequest>({
      query: (input) => ({
        url: `/accounting-expense-sharing/incoming-account-expenses/list?month=${input.month}&year=${input.year}&buildingAcronym=${input.buildingAcronym}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Expense'],
    }),
    fetchIncomingAccountingExpense: builder.mutation<void, FetchIncomingAccountingExpenseRequest>({
      query: (body) => ({
        url: `/accounting-expense-sharing/incoming-account-expenses/fetch`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const { useGetIncomingAccountingExpenseQuery, useFetchIncomingAccountingExpenseMutation } = expenseApi;
