import { HeadcountIntegration, HeadcountIntegrationPeriodRequest } from '@app/models/headcountIntegration';
import api from '@services/api';

/**
 * <p>
 * API endpoints that covers all Headcount Integration CRUD calls
 */
export const headcountIntegrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET BY PERIOD
    getByPeriod: builder.query<HeadcountIntegration[], HeadcountIntegrationPeriodRequest>({
      query: (input) =>
        `/accounting-expense-sharing/headcount-integration/byPeriod?month=${input.month}&year=${input.year}`,
      providesTags: ['HeadcountIntegration'],
    }),
    // PROCESS
    process: builder.mutation<HeadcountIntegration, number>({
      query: (periodId) => ({
        url: `/accounting-expense-sharing/headcount-integration/process?id=${periodId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['HeadcountIntegration', 'Project'],
    }),
  }),
});

export const { useGetByPeriodQuery, useProcessMutation } = headcountIntegrationApi;
