import api from '@services/api';

export interface ResponseTotalGoals {
  period: string;
  data: { [key: string]: number };
}

interface TotalGoalsParams {
  buildingAcronym: Array<string>;
  period: Array<string>;
}

interface TotalShareAccountExpensesParams {
  buildingAcronym: string;
  month: number;
  year: number;
}

export interface ResponseTotalShareAccountExpenses {
  buildingAcronym: string;
  costCenterCode: string;
  period: string;
  amount: number;
  accountingEntryType: string;
}

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    totalGoals: builder.query<Array<ResponseTotalGoals>, TotalGoalsParams>({
      query: (params) => ({
        url: `/accounting-expense-sharing/dashboards/goals`,
        params,
      }),
      providesTags: ['Project'],
    }),
    getTotalShareAccountExpenses: builder.query<
      Array<ResponseTotalShareAccountExpenses>,
      TotalShareAccountExpensesParams
    >({
      query: (params) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/dashboard`,
        params,
      }),
      providesTags: ['Project'],
    }),
  }),
});

export const { useTotalGoalsQuery, useGetTotalShareAccountExpensesQuery } = projectApi;
