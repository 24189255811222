interface Building {
  id: number;
  name: string;
  acronym: string;
  fixedWorkPoints: boolean;
  workPoints: number;
}

export default interface AccountingExpense {
  id: number;
  code: string;
  description: string;
  buildings: Array<Building>;
}

export interface NewOrUpdateAccountingExpense {
  id?: number | null;
  code: string;
  description: string;
  buildingIds: Array<number>;
}

export interface IncomingAccountingExpense {
  code: string;
  description: string;
  expenses: [
    {
      date: string;
      amount: number;
      lastModifiedDate: string;
    },
  ];
}

export enum SharedExpensesProcessingStatus {
  NO_EXPENSES = 'NO_EXPENSES',
  RETRIEVING_EXPENSES = 'RETRIEVING_EXPENSES',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}
