import api from '@services/api';
import { Building, CreateBuildingRequest } from '../models/buildings';

/**
 * <p>
 * API endpoints that covers all Headcount Integration CRUD calls
 */

type UpdateBuildingRequest = {
  id: number;
  body: CreateBuildingRequest;
};

type GetBuildings = {
  searchTerm: string;
  active: boolean;
};

export const headcountIntegrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET BY PERIOD
    getBuildings: builder.query<Building[], GetBuildings>({
      query: (input: GetBuildings) =>
        `/accounting-expense-sharing/buildings/search?searchTerm=${input.searchTerm}&active=${input.active}`,
      providesTags: ['Buildings'],
    }),
    // PROCESS
    createBuilding: builder.mutation<void, CreateBuildingRequest>({
      query: (body) => ({
        url: `/accounting-expense-sharing/buildings/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Buildings'],
    }),
    updateBuilding: builder.mutation<void, UpdateBuildingRequest>({
      query: (input: UpdateBuildingRequest) => ({
        url: `/accounting-expense-sharing/buildings/update`,
        method: 'PUT',
        body: { id: input.id, ...input.body },
      }),
      invalidatesTags: ['Buildings'],
    }),
    disableBuilding: builder.mutation<void, number>({
      query: (id) => ({
        url: `/accounting-expense-sharing/buildings/deactivate?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Buildings'],
    }),
  }),
});

export const {
  useGetBuildingsQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDisableBuildingMutation,
} = headcountIntegrationApi;
