import {
  useFetchIncomingAccountingExpenseMutation,
  useGetIncomingAccountingExpenseQuery,
} from '@/app/services/IncomingAccountingExpeseApi';
import {
  useDownloadTxtFileMutation,
  useLazyDownloadOracleFileQuery,
  useProcesSharedAccountingExpenseMutation,
} from '@/app/services/sharedAccountingExpenseApi';
import { SharedExpensesProcessingStatus } from '@app/models/accountingExpense';
import refresh from '@icons/refresh.svg';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LateralDrawer from '../common/LateralDrawer';
import ConfirmProcess from './ConfirmProcess';

import { SharedAccountingExpenseBuilding } from '@/app/models/sharedAccountingExpense';
import Grid from '@mui/material/Grid';
import StatusDisplay from './StatusDisplay';

const POLLING_INTERVAL = 5000; // 5 seconds

type BuildingProcessProps = {
  building: SharedAccountingExpenseBuilding;
  expensesCostCenter: string;
  infraCostCenter: string;
  status: string;
  month: number;
  year: number;
  period: string;
  showExpenses: boolean;
  setShowExpenses: (value: boolean) => void;
  lastModifiedDate: string;
  setPollingIntervalProcessing: (value: number) => void;
  setLoadingProcessing: (value: boolean) => void;
  processedFile: boolean;
};

function convertToPeriod(year: number, month: number): string {
  const day = 1; // first day of the month
  return String(DateTime.fromObject({ year, month, day }).toISODate());
}

const formatter = new Intl.NumberFormat('pt-BR');

export default function BuildingProcess(props: BuildingProcessProps) {
  const {
    building,
    expensesCostCenter,
    infraCostCenter,
    status,
    month,
    year,
    showExpenses,
    setShowExpenses,
    lastModifiedDate,
    setPollingIntervalProcessing,
    setLoadingProcessing,
    period,
    processedFile,
  } = props;
  const { t } = useTranslation('process');
  const [showConfirm, setShowConfirm] = useState(false);
  const [fetchIncoming] = useFetchIncomingAccountingExpenseMutation();
  const [processExpenses] = useProcesSharedAccountingExpenseMutation();
  const [downloadTxtFile] = useDownloadTxtFileMutation();
  const [trigger, result, lastPromiseInfo] = useLazyDownloadOracleFileQuery();
  const [loadingIncoming, setLoadingIncoming] = useState(false);
  const [pollingIntervalIncoming, setPollingIntervalIncoming] = useState(0);
  const [disabledProcessingButton, setDisabledProcessingButton] = useState(false);
  const previousStatus = useRef('');
  const { data: incomingAccountingExpenses } = useGetIncomingAccountingExpenseQuery(
    {
      buildingAcronym: building.acronym,
      month,
      year,
    },
    {
      pollingInterval: pollingIntervalIncoming,
      skip: !showExpenses,
    },
  );

  const downloadFile = (url: string) => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = url;
    hiddenElement.target = '_self';
    hiddenElement.click();
  };

  async function handleDownload(isProcessedFile: boolean) {
    if (isProcessedFile) {
      const { data } = await trigger({
        buildingAcronym: building.acronym,
        year,
        month,
      });
      if (data && data?.url) {
        downloadFile(data.url);
      } else {
        toast.error(
          t('Erro ao tentar abaixar o arquivo.', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    } else {
      await downloadTxtFile({
        buildingAcronym: building.acronym,
        year,
        month,
      });
    }
  }
  useEffect(() => {
    setLoadingIncoming(status === SharedExpensesProcessingStatus.RETRIEVING_EXPENSES);
    if (
      previousStatus.current === SharedExpensesProcessingStatus.RETRIEVING_EXPENSES &&
      status === SharedExpensesProcessingStatus.PENDING
    ) {
      toast.success('As despesas foram atualizadas.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    previousStatus.current = status;
  }, [status]);

  async function handleFetchExpenses() {
    const response = await fetchIncoming({
      buildingAcronym: building.acronym,
      period: convertToPeriod(year, month),
    });

    if ('data' in response) {
      setPollingIntervalIncoming(POLLING_INTERVAL);
      setPollingIntervalProcessing(POLLING_INTERVAL);
      setLoadingIncoming(true);
    }
  }

  async function handleProcessExpenses() {
    setDisabledProcessingButton(true);
    const response = await processExpenses({
      buildingAcronym: building.acronym,
      period: convertToPeriod(year, month),
    });
    setPollingIntervalProcessing(1000);
    if ('data' in response) {
      setTimeout(() => {
        setLoadingProcessing(true);
        setPollingIntervalProcessing(POLLING_INTERVAL);
        setDisabledProcessingButton(false);
      }, 1100);
    } else {
      setPollingIntervalProcessing(0);
      setLoadingProcessing(false);
      setDisabledProcessingButton(false);
    }

    setShowConfirm(false);
  }

  useEffect(() => {
    const hasExpenses = incomingAccountingExpenses && incomingAccountingExpenses?.length > 0;
    setDisabledProcessingButton(!hasExpenses);
  }, [incomingAccountingExpenses]);

  return (
    <>
      <Card
        sx={{
          width: '100%',
          border: '1px solid #E4E4E4',
          marginBottom: '16px',
          ':hover': { border: '1px solid #888888' },
        }}
        onClick={() => setShowExpenses(!showExpenses)}
      >
        <CardContent sx={{ padding: '24px', minWidth: 1000 }}>
          <Grid container spacing={1} columns={16} alignItems="flex-start">
            <Grid item md={2}>
              <Box sx={{ marginRight: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('Prédio')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 550 }}>{building.name}</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={5}>
              <Box sx={{ marginRight: '24px', marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('C.C. de Correlatos')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 600 }}>
                  {expensesCostCenter}
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={5}>
              <Box sx={{ marginRight: '24px', marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('C.C. de Infra')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 600 }}> {infraCostCenter}</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={3}>
              <Box sx={{ marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('Status')}
                </Typography>
                <StatusDisplay status={status} lastModifiedDate={lastModifiedDate} />
              </Box>
            </Grid>
            <Grid
              item
              md={0.9}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              margin="auto"
            >
              <div
                style={{
                  border: 'solid #707070',
                  borderWidth: '0 2px 2px 0',
                  width: '12px',
                  height: '12px',
                  transform: 'rotate(316deg)',
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <LateralDrawer open={showExpenses} setOpen={setShowExpenses} position="right" width="700px">
        <>
          <Typography variant="h1" style={{ fontSize: '21px' }}>
            {building.name}
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('Período')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{period}</Typography>
            </div>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('Status')}:`}</Typography>
              <StatusDisplay status={status} lastModifiedDate={lastModifiedDate} />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
            <div style={{ width: '50%', paddingRight: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('C.C. de Correlatos')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{expensesCostCenter}</Typography>
            </div>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('C.C. de Infra')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{infraCostCenter}</Typography>
            </div>
          </div>
          <Divider sx={{ marginTop: '16px' }} />
          <TableContainer
            sx={{
              marginTop: '24px',
              marginBottom: '24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              height: '100%',
            }}
          >
            <Table stickyHeader aria-label="Expenses table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    Despesa
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    Valor (R$)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ flexGrow: 1, overflow: 'auto' }}>
                {loadingIncoming && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '30vh',
                        }}
                      >
                        <CircularProgress size={50} color="secondary" />

                        <div style={{ fontSize: '20px', marginBottom: '20px' }}>{t('Buscando resultados')}</div>
                        <Typography>{t('Por favor aguarde...')}</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {!loadingIncoming &&
                  incomingAccountingExpenses?.map((row) => (
                    <>
                      <TableRow key={row.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align="left"
                          sx={{ width: '50%', backgroundColor: '#FAFAFA', fontWeight: 'bold' }}
                        >{`${row.code} - ${row.description}`}</TableCell>
                        <TableCell align="left" sx={{ width: '50%', backgroundColor: '#FAFAFA' }}>
                          ---
                        </TableCell>
                      </TableRow>
                      {row.expenses.map((e) => (
                        <TableRow
                          key={`${e.amount}-${e.lastModifiedDate}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            align="left"
                            sx={{ width: '50%', paddingLeft: '40px', backgroundColor: '#FFFFFF' }}
                          >{`Data: ${DateTime.fromFormat(e.date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')}`}</TableCell>
                          <TableCell align="left" sx={{ width: '50%', backgroundColor: '#FFFFFF' }}>
                            {e.amount && formatter.format(e.amount)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                {!loadingIncoming && incomingAccountingExpenses?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <div style={{ width: '100%', alignItems: 'center', textAlign: 'center' }}>
                        <Typography sx={{ marginY: '20px' }}>
                          Ainda não existem despesas referentes a este período
                        </Typography>
                        <div style={{ width: '100%', alignItems: 'center' }}>
                          <Button variant="primary" onClick={handleFetchExpenses}>
                            Buscar Custos
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: '8px',
              marginTop: 'auto',
            }}
          >
            {incomingAccountingExpenses?.length !== 0 && (
              <>
                <Button
                  onClick={() => handleFetchExpenses()}
                  variant="tertiary"
                  sx={{ marginLeft: '16px', marginRight: 'auto' }}
                  disabled={loadingIncoming}
                >
                  <img style={{ marginRight: '8px' }} src={refresh} alt={t('atualizar') as string} />
                  {t('Atualizar custos')}
                </Button>
                <Button onClick={() => handleDownload(processedFile)} variant="tertiary" sx={{ marginLeft: '16px' }}>
                  {t('Baixar Arquivo')}
                </Button>
              </>
            )}
            <Button onClick={() => setShowExpenses(false)} variant="secondary" sx={{ marginLeft: '16px' }}>
              {t('Cancelar')}
            </Button>
            <Button
              onClick={() => setShowConfirm(true)}
              variant="primary"
              sx={{ marginLeft: '16px' }}
              disabled={disabledProcessingButton || loadingIncoming || incomingAccountingExpenses?.length === 0}
            >
              {t('Processar')}
            </Button>
          </div>
        </>
      </LateralDrawer>
      <ConfirmProcess
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleProcessExpenses}
        year={year}
        month={month}
        buildingAcronym={building.acronym}
      />
    </>
  );
}
