import Project, { UpdateProject } from '@/app/models/project';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import AmountField from '@/components/common/amountField';
import PercentField from '@/components/common/percentField';
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

type RowFormats = string | number | boolean;

interface Column {
  id: 'code' | 'name' | 'building' | 'type' | 'receivedHc' | 'consideredHc' | 'fixed' | 'goal';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: RowFormats) => JSX.Element;
}

type RowData = {
  id: number;
  code: string;
  name: string;
  building: string;
  type: string;
  receivedHc: number;
  consideredHc: number;
  fixed: number;
  goal: number;
  buildingAcronym: string;
};

const columns: readonly Column[] = [
  {
    id: 'code',
    label: 'Código',
    minWidth: 80,
  },
  { id: 'name', label: 'Nome Projeto', minWidth: 130 },
  { id: 'building', label: 'Prédio', minWidth: 190 },
  { id: 'type', label: 'Tipo de Rateio', minWidth: 130 },
  { id: 'receivedHc', label: 'HC Recebido', minWidth: 70, align: 'center' },
  { id: 'consideredHc', label: 'HC Considerado', minWidth: 70, align: 'center' },
  { id: 'fixed', label: 'Fixo (%)', minWidth: 80, align: 'center' },
  { id: 'goal', label: 'Meta (R$)', minWidth: 80, align: 'center' },
];

const createRows = (content: Array<Project>) =>
  content.map(
    (project: Project): RowData =>
      ({
        id: project.id,
        code: project.costCenter.code,
        name: project.costCenter.name,
        building: `${project.building?.name ? project.building?.name : ''}(${
          project.building?.acronym ? project.building?.acronym : ''
        })`,
        buildingAcronym: project.building?.acronym || '',
        type: project.sharingType,
        receivedHc: project.headcount,
        consideredHc: project.usedHeadcount || 0,
        fixed: project.fixedPercentage || 0,
        goal: project.goal || 0,
      } as RowData),
  );

interface ProjectTableRowProps {
  row: RowData;
  onClickRow: (clickedRow: RowData) => void;
  onDelete: (row: RowData) => void;
  selectedPeriod: string;
  map: Map<string, Map<number, UpdateProject>>;
  setMap: (map: Map<string, Map<number, UpdateProject>>) => void;
}

function ProjectTableRow(props: ProjectTableRowProps) {
  const { row, onClickRow: handleOnClickRow, onDelete: handleOnDelete, selectedPeriod, map, setMap } = props;
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });
  const { t } = useTranslation('expense');
  const periodMap = map?.get(selectedPeriod);
  const currentProject = periodMap?.has(row.id)
    ? periodMap.get(row.id)
    : {
        id: row.id,
        sharingType: row.type,
        buildingAcronym: row.buildingAcronym,
        usedHeadcount: row.consideredHc,
        fixedPercentage: row.fixed,
        goal: row.goal,
      };

  const [componentLoaded, setComponentLoaded] = useState(false);

  useEffect(() => {
    setComponentLoaded(true);
  }, []);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      style={{ minHeight: '40px', height: '40px', maxHeight: '40px', cursor: 'pointer' }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        const key = `${column.id}-${row.id}`;
        let formattedValue = column.format ? column.format(value) : value;
        if (column.id === 'building') {
          formattedValue = (
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ minWidth: 180, fontSize: '13px' }}
              value={currentProject?.buildingAcronym || ''}
              variant="standard"
              onChange={(e) => {
                if (componentLoaded) {
                  if (currentProject) {
                    currentProject.buildingAcronym = e.target.value;
                    if (periodMap) {
                      periodMap.set(row.id, currentProject);
                      map.set(selectedPeriod, periodMap);
                      setMap(new Map(map));
                    }
                  }
                }
              }}
            >
              <MenuItem key={-1} value="">
                <em>--- Nenhum ---</em>
              </MenuItem>
              {buildings?.map((building) => (
                <MenuItem key={building.id} value={building.acronym}>
                  {`${building.name} (${building.acronym})`}
                </MenuItem>
              ))}
            </Select>
          );
        }
        if (column.id === 'type') {
          formattedValue = (
            <Select
              sx={{ minWidth: 120, fontSize: '13px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                (map.get(selectedPeriod)?.has(row.id) ? map.get(selectedPeriod)?.get(row.id)?.sharingType : row.type) ||
                ''
              }
              variant="standard"
              onChange={(e) => {
                if (componentLoaded) {
                  if (currentProject) {
                    currentProject.sharingType = e.target.value as string;
                    if (periodMap) {
                      periodMap.set(row.id, currentProject);
                      map.set(selectedPeriod, periodMap);
                      setMap(new Map(map));
                    }
                  }
                }
              }}
            >
              <MenuItem value="HEADCOUNT">Headcount</MenuItem>
              <MenuItem value="FIXED_PERCENTAGE">Percentual Fixo</MenuItem>
              <MenuItem value="INFRA">Custo CESAR</MenuItem>
              <MenuItem value="NOT_SHAREABLE">Não Rateável</MenuItem>
              <MenuItem value="META">Meta</MenuItem>
            </Select>
          );
        }
        if (column.id === 'receivedHc') {
          formattedValue = (
            <AmountField value={value as number} label="" disable sx={{ width: 80, margin: 0 }} onChange={() => {}} />
          );
        }
        if (column.id === 'consideredHc') {
          formattedValue = (
            <AmountField
              value={currentProject?.usedHeadcount as number}
              label=""
              disable={
                currentProject?.sharingType ? currentProject.sharingType !== 'HEADCOUNT' : row.type !== 'HEADCOUNT'
              }
              sx={{ width: 80, margin: 0 }}
              onChange={(e) => {
                if (componentLoaded) {
                  if (currentProject && currentProject.usedHeadcount !== e) {
                    currentProject.usedHeadcount = e;
                    if (periodMap) {
                      periodMap.set(row.id, currentProject);
                      map.set(selectedPeriod, periodMap);
                      setMap(new Map(map));
                    }
                  }
                }
              }}
            />
          );
        }
        if (column.id === 'fixed') {
          formattedValue = (
            <PercentField
              value={currentProject?.fixedPercentage as number}
              label=""
              disable={
                currentProject?.sharingType
                  ? currentProject.sharingType !== 'FIXED_PERCENTAGE'
                  : row.type !== 'FIXED_PERCENTAGE'
              }
              sx={{ width: 70, margin: 0 }}
              onChange={(e) => {
                if (componentLoaded) {
                  if (currentProject) {
                    currentProject.fixedPercentage = e;
                    if (periodMap) {
                      periodMap.set(row.id, currentProject);
                      map.set(selectedPeriod, periodMap);
                      setMap(new Map(map));
                    }
                  }
                }
              }}
            />
          );
        }
        if (column.id === 'goal') {
          formattedValue = (
            <AmountField
              value={currentProject?.goal as number}
              label=""
              disable={currentProject?.sharingType ? currentProject.sharingType !== 'META' : row.type !== 'META'}
              sx={{ width: 130, margin: 0 }}
              onChange={(e) => {
                if (componentLoaded) {
                  if (currentProject) {
                    currentProject.goal = e;
                    if (periodMap) {
                      periodMap.set(row.id, currentProject);
                      map.set(selectedPeriod, periodMap);
                      setMap(new Map(map));
                    }
                  }
                }
              }}
            />
          );
        }
        return (
          <TableCell
            key={key}
            component="td"
            scope="row"
            align={column.align}
            onClick={() => handleOnClickRow(row)}
            sx={{ height: 5, fontSize: '13px', paddingY: 0, paddingX: 1 }}
          >
            {formattedValue}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

type ProjectsTableProps = {
  items: Array<Project>;
  onRowSelect: (item: Project) => void;
  onDelete: (item: Project) => void;
  rowsPerPage: number;
  page: number;
  handleChangePage: (event: any | null, page: number) => void;
  handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  itemCount: number;
  selectedPeriod: string;
  modifiedProjectsMap: Map<string, Map<number, UpdateProject>>;
  setModifiedProjectsMap: (map: Map<string, Map<number, UpdateProject>>) => void;
};

export default function ProjectsTable(props: ProjectsTableProps) {
  const { t } = useTranslation('expense');
  const [rows, setRows] = useState<Array<RowData>>([]);
  const calcTableHeight = () => window.innerHeight - 390;
  const [tableHeight, setTableHeight] = useState(calcTableHeight);
  const {
    items,
    onRowSelect,
    onDelete,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    itemCount,
    selectedPeriod,
    modifiedProjectsMap,
    setModifiedProjectsMap,
  } = props;

  useEffect(() => {
    setRows(createRows(items));
  }, [items]);

  const rowToPAccountingExpense = (row: RowData): Project => {
    const findItem = items.filter((item: Project) => item.id === row.id);
    if (findItem.length === 0) {
      toast.error(`${t('A despesa selecionado não existe mais...')}`, {
        position: toast.POSITION.TOP_RIGHT,
        delay: 1,
      });
    }
    return findItem[0];
  };

  const handleRowClick = (row: RowData) => {
    onRowSelect(rowToPAccountingExpense(row));
  };

  const handleDelete = (row: RowData) => {
    onDelete(rowToPAccountingExpense(row));
  };

  useEffect(() => {
    window.addEventListener('resize', () => setTableHeight(calcTableHeight), false);
  }, []);

  return (
    <>
      <TableContainer
        sx={{
          height: tableHeight,
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Expense Table">
          <TableHead sx={{ fontWeight: 700 }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold', paddingLeft: 5 }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row: RowData) => (
                <ProjectTableRow
                  key={row.id}
                  row={row}
                  onClickRow={handleRowClick}
                  onDelete={handleDelete}
                  selectedPeriod={selectedPeriod}
                  map={modifiedProjectsMap}
                  setMap={setModifiedProjectsMap}
                />
              ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                  {t('Nenhum conteúdo encontrado')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter sx={{ width: '100%' }} />
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ width: '100%' }}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={itemCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('de')} ${count}`}
        labelRowsPerPage={t('itens por página')}
      />
    </>
  );
}
