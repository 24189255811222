import PeriodDetails from '@/components/periodDetails/PeriodDetails';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

const PeriodDetailsScreen = styled.div`
  width: 100%;
  margin-right: 24px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

interface PeriodDetailsPageProps {
  selectedPeriod: string;
  setSelectedPeriod: (i: string) => void;
  numberOfPeriods?: number;
}

function PeriodDetailsPage(props: PeriodDetailsPageProps) {
  const { selectedPeriod, setSelectedPeriod, numberOfPeriods } = props;
  return (
    <PeriodDetailsScreen>
      <PeriodDetails
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        numberOfPeriods={numberOfPeriods}
      />
      <Outlet />
    </PeriodDetailsScreen>
  );
}
export default PeriodDetailsPage;
