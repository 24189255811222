import { getCurrentPeriod } from '@/app/utils/dateUtils';
import SharedExpensesDashboardPage from '@/pages/dashboard/sharedexpenses';
import TotalGoalsPage from '@/pages/dashboard/totalgoals';
import PersonProductRoles from '@/pages/personProductRoles';
import Redirect from '@/pages/redirecting';
import { PAGE_PATHS, PAGE_ROLES } from '@app/constants/pages';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import Buildings from '@pages/buildings';
import Expenses from '@pages/expenses';
import Layout from '@pages/layout';
import Login from '@pages/login';
import PeriodDetailsPage from '@pages/periodDetails';
import Process from '@pages/process';
import Projects from '@pages/projects';
import { useState } from 'react';

import { Route, Routes, useSearchParams } from 'react-router-dom';

function AppRoute() {
  const currentPeriod = getCurrentPeriod();
  const [selectedPeriod, setSelectedPeriod] = useState(currentPeriod);

  const [searchParams] = useSearchParams();
  const numberOfPeriods = Number(searchParams.get('numberOfPeriods'));

  return (
    <Routes>
      <Route path={PAGE_PATHS.LOGIN} element={<Login />} />
      <Route element={<RequireLogin />}>
        <Route element={<Layout />}>
          <Route path={PAGE_PATHS.ROOT} element={<Redirect />} />

          <Route
            element={
              <PeriodDetailsPage
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                numberOfPeriods={numberOfPeriods}
              />
            }
          >
            <Route element={<RequireAuth roles={PAGE_ROLES.BUILDINGS} />}>
              <Route path={PAGE_PATHS.BUILDINGS} element={<Buildings selectedPeriod={selectedPeriod} />} />
            </Route>

            <Route element={<RequireAuth roles={PAGE_ROLES.EXPENSES} />}>
              <Route path={PAGE_PATHS.EXPENSES} element={<Expenses selectedPeriod={selectedPeriod} />} />
            </Route>

            <Route element={<RequireAuth roles={PAGE_ROLES.PROJECTS} />}>
              <Route path={PAGE_PATHS.PROJECTS} element={<Projects selectedPeriod={selectedPeriod} />} />
            </Route>
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.PROCESS} />}>
            <Route
              path={PAGE_PATHS.PROCESS}
              element={<Process currentPeriod={currentPeriod} numberOfPeriods={numberOfPeriods} />}
            />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.DASHBOARDS_GOALS} />}>
            <Route path={PAGE_PATHS.DASHBOARDS_GOALS} element={<TotalGoalsPage />} />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.DASHBOARDS_SHARED_EXPENSES} />}>
            <Route path={PAGE_PATHS.DASHBOARDS_SHARED_EXPENSES} element={<SharedExpensesDashboardPage />} />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.PERMISSIONS} />}>
            <Route path={PAGE_PATHS.PERMISSIONS} element={<PersonProductRoles />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;
