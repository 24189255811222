import api from '@services/api';
import { PaginatedResponse } from '../models/common';
import Project, { CreateProject, GetProjectsByPeriod, UpdateProject } from '../models/project';

/**
 * <p>
 * API endpoints that covers all PROJECT Integration CRUD calls
 */
export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // SEARCH
    listByPeriod: builder.query<PaginatedResponse<Project>, GetProjectsByPeriod>({
      query: (input: GetProjectsByPeriod) =>
        `/accounting-expense-sharing/projects/search?month=${input.month}&year=${input.year}&page=${input.page}&size=${
          input.size
        }&buildingAcronym=${input.buildingAcronym}${input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''}`,
      providesTags: ['Project'],
    }),
    // CREATE
    createProject: builder.mutation<Project, CreateProject>({
      query: (newProject) => ({
        url: `/accounting-expense-sharing/projects/create`,
        method: 'POST',
        body: newProject,
      }),
      invalidatesTags: ['Project'],
    }),
    // UPDATE
    updateProjects: builder.mutation<{ projects: Project[] }, { projects: UpdateProject[] }>({
      query: (projects) => ({
        url: `/accounting-expense-sharing/projects/update`,
        method: 'PUT',
        body: projects,
      }),
      invalidatesTags: ['Project'],
    }),
  }),
});

export const { useListByPeriodQuery, useCreateProjectMutation, useUpdateProjectsMutation } = projectApi;
