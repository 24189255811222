import { useGetByPeriodQuery, useProcessMutation } from '@/app/services/headcountIntegrationApi';
import {
  datetimeLabelFormated,
  getCurrentPeriod,
  getPreviousPeriodsList,
  monthFromPeriod,
  periodLabel,
  yearFromPeriod,
} from '@/app/utils/dateUtils';
import exclamation from '@icons/exclamation.svg';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Card, CardContent, Chip, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import IntegrationHistoryModal from './IntegrationHistoryModal';
import ProcessDialog from './ProcessDialog';

interface PeriodDetailsProps {
  selectedPeriod: string;
  setSelectedPeriod: (i: string) => void;
  numberOfPeriods?: number;
}

function PeriodDetails(props: PeriodDetailsProps) {
  const { selectedPeriod, setSelectedPeriod, numberOfPeriods } = props;

  const { i18n, t } = useTranslation('periodDetails');

  const { keycloak } = useKeycloak();

  const isPMO = keycloak.hasResourceRole('PMO', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);

  const { data } = useGetByPeriodQuery({
    year: yearFromPeriod(selectedPeriod),
    month: monthFromPeriod(selectedPeriod),
  });

  const [processMutation] = useProcessMutation();

  async function handleProcess(id: number) {
    const response = await processMutation(id);
    if ('data' in response) {
      toast.success(
        t('Processo de importação autorizado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <Card sx={{ boxShadow: 3, display: 'flex', minHeight: '72px', alignItems: 'center' }}>
      {true && (
        <CardContent
          sx={{ display: 'flex', maxHeight: '24px', alignItems: 'center', paddingTop: '22px', width: '100%' }}
        >
          <Typography
            sx={{ margin: '0 24px', fontSize: 14, fontWeight: 'bold', color: '#4B4B4B' }}
            color="text.secondary"
            gutterBottom
          >
            {t('Período Corrente: ')}
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
              label="Age"
            >
              {getPreviousPeriodsList(getCurrentPeriod(), numberOfPeriods).map((period) => (
                <MenuItem key={period} value={period}>
                  {periodLabel(period, i18n.language)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              marginLeft: '40px',
              marginRight: '5px',
              fontSize: 14,
              fontWeight: 'bold',
              color: '#4B4B4B',
            }}
            color="text.secondary"
          >
            {`${t('Headcount')}:`}
          </Typography>
          {data?.length && data?.length > 0 ? (
            <>
              <Tooltip
                placement="top"
                title={
                  data[0].status === 'NEW'
                    ? t(
                        'O sistema de Rateio Direto enviou as informações de Headcount e precisa de autorização para iniciar o processo de importação dessas informações.',
                      )
                    : ''
                }
              >
                {data[0].status === 'NEW' ? (
                  <Chip
                    sx={{
                      margin: '0 15px',
                      background: '#FFF7E1',
                      color: '#E3AC04',
                      maxHeight: '24px',
                    }}
                    icon={
                      <img
                        style={{
                          filter:
                            'invert(66%) sepia(58%) saturate(2224%) hue-rotate(9deg) brightness(101%) contrast(97%)',
                        }}
                        src={exclamation}
                        alt={t('exclamação') as string}
                      />
                    }
                    label={t('Sincronizado')}
                  />
                ) : (
                  <Chip
                    sx={{
                      margin: '0 15px',
                      background: '#C8F8D3',
                      color: '#28A745',
                      maxHeight: '24px',
                    }}
                    label={t('Autorizado')}
                  />
                )}
              </Tooltip>
              <Typography
                onClick={() => setOpenHistoryModal(true)}
                sx={{
                  marginLeft: '12px',
                  marginRight: '5px',
                  fontSize: 13,
                  color: '#757575',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                color="text.secondary"
              >
                {`${t('Última sincronização')} ${datetimeLabelFormated(data[0].creationDate)}`}
              </Typography>
            </>
          ) : (
            <Chip
              sx={{ margin: '0 15px', background: '#E4E4E4', color: '#888888', maxHeight: '24px' }}
              label={t('Aguardando Sincronização do Rateio Direto')}
            />
          )}
          <Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: 'auto' }}>
            {data?.length === 0 && (
              <Tooltip
                placement="top"
                title="O sistema está aguardando o fechamento do Rateio Direto deste período escolhido. Após a conclusão, você poderá autorizar o processamento."
                arrow
              >
                <InfoIcon sx={{ marginRight: 1 }} inheritViewBox />
              </Tooltip>
            )}
            <Button
              variant="primary"
              disabled={!(data?.length && data?.length > 0) || data[0].status !== 'NEW' || !isPMO}
              onClick={() => {
                setOpenProcessDialog(true);
              }}
            >
              {t('Autorizar')}
            </Button>
          </Stack>
        </CardContent>
      )}
      <IntegrationHistoryModal
        open={openHistoryModal}
        handleClose={() => setOpenHistoryModal(false)}
        selectedPeriod={selectedPeriod}
      />
      <ProcessDialog
        open={openProcessDialog}
        handleClose={() => setOpenProcessDialog(false)}
        handleProcess={() => {
          if (data && data[0]) {
            handleProcess(data[0].id as number);
          }
        }}
      />
    </Card>
  );
}

export default PeriodDetails;
