import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Drawer } from '@mui/material';

type CallbackFunction = (v: boolean) => void;
type LateralDrawerProps = {
  open: boolean;
  setOpen: CallbackFunction;
  position: 'right' | 'left' | 'top' | 'bottom';
  children: ReactJSXElement;
  width?: string;
};

function LateralDrawer(props: LateralDrawerProps) {
  const { open, setOpen, position, children, width = '480px' } = props;
  return (
    <Drawer
      ModalProps={{
        onBackdropClick: () => {
          setOpen(false);
        },
      }}
      open={open}
      anchor={position}
    >
      <div
        style={{
          width,
          display: 'flex',
          flexDirection: 'column',
          margin: '24px',
          height: 'calc(100vh - 48px)',
          maxHeight: 'calc(100vh - 48px)',
          flexGrow: 1,
        }}
      >
        {children}
      </div>
    </Drawer>
  );
}

export default LateralDrawer;
