import AccountingExpense from '@app/models/accountingExpense';
import ActionsMenu, { ActionMenuOption } from '@components/common/actionsMenu';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type RowFormats = string | number | boolean;

interface Column {
  id: 'code' | 'description' | 'linked' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: RowFormats) => JSX.Element;
}

type RowData = {
  code: string;
  description: string;
  linked: string;
  actions: boolean;
};

const columns: readonly Column[] = [
  {
    id: 'code',
    label: 'Cód. da Despesa',
    minWidth: 130,
  },
  { id: 'description', label: 'Despesa', minWidth: 130 },
  {
    id: 'linked',
    label: 'Prédios Associados',
    minWidth: 130,
  },
  { id: 'actions', label: 'Ações', minWidth: 80, align: 'center' },
];

const createRows = (content: Array<AccountingExpense>) =>
  content.map(
    (accountingExpense: AccountingExpense) =>
      ({
        code: accountingExpense.code,
        description: accountingExpense.description,
        linked: accountingExpense.buildings.map((building) => building.acronym).join(', '),
        actions: true,
      } as RowData),
  );

interface ExpenseTableRowProps {
  row: RowData;
  onClickRow: (clickedRow: RowData) => void;
  onDelete: (row: RowData) => void;
}

function ExpenseTableRow(props: ExpenseTableRowProps) {
  const { row, onClickRow: handleOnClickRow, onDelete: handleOnDelete } = props;

  const { t } = useTranslation('expense');

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.code}
      style={{ minHeight: '40px', height: '40px', maxHeight: '40px', cursor: 'pointer' }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        const key = `${column.id}-${row.code}`;
        const isActionMenu = column.id === 'actions';
        const formattedValue = column.format ? column.format(value) : value;
        if (isActionMenu) {
          return (
            <TableCell key={key} align="center" component="td" scope="row" sx={{ fontSize: '13px', padding: 0 }}>
              <ActionsMenu
                actionList={
                  [
                    {
                      icon: <EditOutlinedIcon />,
                      label: t('Editar Despesa'),
                      actionCallback: () => handleOnClickRow(row),
                    },
                    {
                      icon: <DeleteOutlineOutlinedIcon />,
                      label: t('Excluir Despesa'),
                      actionCallback: () => handleOnDelete(row),
                    },
                  ] as Array<ActionMenuOption>
                }
              />
            </TableCell>
          );
        }
        return (
          <TableCell
            key={key}
            component="td"
            scope="row"
            align="left"
            onClick={() => handleOnClickRow(row)}
            sx={{ height: 5, fontSize: 13 }}
          >
            {formattedValue}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

type ExpenseTableProps = {
  items: Array<AccountingExpense>;
  onRowSelect: (item: AccountingExpense) => void;
  onDelete: (item: AccountingExpense) => void;
};

export default function ExpenseTable(props: ExpenseTableProps) {
  const { t } = useTranslation('expense');
  const [rows, setRows] = useState<Array<RowData>>([]);
  const { items, onRowSelect, onDelete } = props;

  useEffect(() => {
    setRows(createRows(items));
  }, [items]);

  const rowToPAccountingExpense = (row: RowData): AccountingExpense => {
    const findItem = items.filter((item: AccountingExpense) => item.code === row.code);
    if (findItem.length === 0) {
      toast.error(`${t('A despesa selecionado não existe mais...')}`, {
        position: toast.POSITION.TOP_RIGHT,
        delay: 1,
      });
    }
    return findItem[0];
  };

  const handleRowClick = (row: RowData) => {
    onRowSelect(rowToPAccountingExpense(row));
  };

  const handleDelete = (row: RowData) => {
    onDelete(rowToPAccountingExpense(row));
  };

  return (
    <TableContainer sx={{ marginBottom: '24px' }}>
      <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Expense Table">
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row: RowData) => (
              <ExpenseTableRow key={row.code} row={row} onClickRow={handleRowClick} onDelete={handleDelete} />
            ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={4}>
                {t('Nenhum conteúdo encontrado')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
