import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'gray',
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    borderRadius: 1,
  },
}));

interface MultSelecionItemProps {
  fieldLabel?: string;
  allItems: Array<string>;
  selectedItems: Array<string>;
  setSelectedItems: (items: Array<string>) => void;
  labelFnc?: (value: string) => string;
}

function MultSelecionItem({ fieldLabel, allItems, selectedItems, setSelectedItems, labelFnc }: MultSelecionItemProps) {
  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    if (value?.length !== 0) {
      setSelectedItems(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const formater = (value: string): string => (labelFnc ? labelFnc(value) : value);

  return (
    <FormControl sx={{ m: 1, width: 250 }}>
      {fieldLabel && (
        <Typography variant="h3" color="text.secondary">
          {fieldLabel}
        </Typography>
      )}
      <Select
        size="small"
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected) =>
          selected
            .filter((x) => x !== '')
            .map(formater)
            .join(', ')
        }
        MenuProps={MenuProps}
        sx={{ height: 32, fontSize: '13px', padding: 0, margin: 0 }}
      >
        {allItems.map((name) => (
          <MenuItem key={name} value={name} sx={{ height: 35 }}>
            <CustomCheckbox checked={selectedItems.indexOf(name) > -1} />
            <ListItemText primary={formater(name)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultSelecionItem;
