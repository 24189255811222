import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

type OnFilterChange = (value: string) => void;

type SearchProps = {
  label?: string | null;
  placeholder: string;
  onFilterChange: OnFilterChange;
};

export default function SearchComponent(props: SearchProps) {
  const {
    label = '', // default value
    placeholder,
    onFilterChange,
  } = props;
  return (
    <Box>
      {label && <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>{label}</Typography>}
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          mb: 2,
          bgcolor: 'white',
          borderColor: 'gray',
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
          },
        }}
      >
        <OutlinedInput
          id="search-field"
          type="text"
          sx={{ width: 320, height: 32, fontSize: 13 }}
          placeholder={placeholder}
          onChange={(e) => onFilterChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
