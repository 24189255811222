import { Building } from '@/app/models/buildings';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

function displayBuilding(building: Building) {
  return `${building.name} (${building.acronym})`;
}

interface BuildingSelectionProps {
  buildingAcronym: string;
  setBuildingAcronym: Dispatch<SetStateAction<string>>;
}

export default function BuildingSelection(props: BuildingSelectionProps) {
  const { buildingAcronym, setBuildingAcronym } = props;
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });
  const { t } = useTranslation('projects');

  return (
    <Select
      displayEmpty
      labelId="building-select-label"
      id="building-select"
      sx={{ height: '32px', width: '100%', fontSize: '13px', padding: 0, margin: 0 }}
      value={buildingAcronym}
      onChange={(event: SelectChangeEvent<string>) => {
        setBuildingAcronym(event.target.value);
      }}
    >
      <MenuItem disabled value="">
        <em>{t('Selecione o prédio')}</em>
      </MenuItem>
      {buildings?.map((building) => (
        <MenuItem key={building.id} value={building.acronym} sx={{ fontSize: '13px' }}>
          {displayBuilding(building)}
        </MenuItem>
      ))}
    </Select>
  );
}
