import { FormControl, OutlinedInput, Typography } from '@mui/material';

interface AccountingExpenseCodeFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

export default function AccountingExpenseCodeField(props: AccountingExpenseCodeFieldProps) {
  const { label, value, onChange } = props;
  return (
    <FormControl fullWidth>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        fullWidth
        sx={{
          height: 35,
        }}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        inputProps={{ maxLength: 255 }}
      />
    </FormControl>
  );
}
