import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ProcessDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleProcess: () => void;
};

function ProcessDialog(props: ProcessDialogProps) {
  const { t } = useTranslation('periodDetails');
  const { open, handleClose, handleProcess } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('Autorizar importação')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('Tem certeza que deseja autorizar o processo de importação?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          {t('Não')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleProcess();
            handleClose();
          }}
          autoFocus
        >
          {t('Sim')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProcessDialog;
