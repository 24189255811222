import { HeadcountIntegration } from '@/app/models/headcountIntegration';
import { useGetByPeriodQuery } from '@/app/services/headcountIntegrationApi';
import { datetimeLabelFormated, monthFromPeriod, yearFromPeriod } from '@/app/utils/dateUtils';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MutableRefObject, useRef } from 'react';

import { useTranslation } from 'react-i18next';

type IntegrationHistoryModalProps = {
  open: boolean;
  handleClose: () => void;
  selectedPeriod: string;
};

function IntegrationHistoryModal(props: IntegrationHistoryModalProps) {
  const { i18n, t } = useTranslation('periodDetails');
  const { open, handleClose, selectedPeriod } = props;
  const tableEl = useRef() as unknown as MutableRefObject<HTMLInputElement>;

  const { data } = useGetByPeriodQuery({
    year: yearFromPeriod(selectedPeriod),
    month: monthFromPeriod(selectedPeriod),
  });

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{`Histórico ${selectedPeriod}`}</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <DialogContentText sx={{ marginBottom: '24px' }}>
          {t('Confira o histórico de Dados do Headcount')}{' '}
        </DialogContentText>

        <TableContainer component={Paper} style={{ maxHeight: '60vh' }} ref={tableEl}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow style={{ color: '#4B4B4B', height: '40px', fontSize: '13px' }}>
                <TableCell
                  style={{
                    width: '60%',
                    fontWeight: 'bold',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    fontSize: '13px',
                  }}
                >
                  {t('Data de envio')}
                </TableCell>
                <TableCell
                  style={{
                    width: '30%',
                    fontWeight: 'bold',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    fontSize: '13px',
                  }}
                >
                  {t('Status')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row: HeadcountIntegration, i) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: '40px',
                    border: '0px',
                    cursor: 'pointer',
                    backgroundColor: `${i % 2 === 0 ? '#FFFFFF' : '#FAFAFA'}`,
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}
                    align="left"
                  >
                    {`${t('Última sincronização')} ${datetimeLabelFormated(row.creationDate)}`}
                  </TableCell>
                  <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                    {row.status === 'NEW' ? 'Sincronizado' : 'Autorizado'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
            style={{
              marginLeft: 'auto',
              marginRight: '8px',
              marginTop: '47px',
            }}
          >
            {t('Fechar')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default IntegrationHistoryModal;
