import AccountingExpense, { NewOrUpdateAccountingExpense } from '@app/models/accountingExpense';
import api from '@services/api';

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountingExpenses: builder.query<Array<AccountingExpense>, string>({
      query: (query: string) => ({
        url: `/accounting-expense-sharing/accounting-expenses/search?searchTerm=${query}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Expense'],
    }),

    createAccountingExpense: builder.mutation<void, NewOrUpdateAccountingExpense>({
      query: (accountingExpense: NewOrUpdateAccountingExpense) => ({
        url: '/accounting-expense-sharing/accounting-expenses/create',
        method: 'POST',
        body: {
          code: accountingExpense.code,
          description: accountingExpense.description,
          buildingIds: accountingExpense.buildingIds,
        },
      }),
      invalidatesTags: ['Expense'],
    }),

    updateAccountingExpense: builder.mutation<AccountingExpense, NewOrUpdateAccountingExpense>({
      query: (accountingExpense: NewOrUpdateAccountingExpense) => ({
        url: `/accounting-expense-sharing/accounting-expenses/update`,
        method: 'PUT',
        body: {
          id: accountingExpense.id,
          code: accountingExpense.code,
          description: accountingExpense.description,
          buildingIds: accountingExpense.buildingIds,
        },
      }),
      invalidatesTags: ['Expense'],
    }),

    deleteAccountingExpense: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/accounting-expense-sharing/accounting-expenses/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const {
  useGetAccountingExpensesQuery,
  useCreateAccountingExpenseMutation,
  useUpdateAccountingExpenseMutation,
  useDeleteAccountingExpenseMutation,
} = expenseApi;
