import { PreviewEntry, SharedAccountingExpense } from '@app/models/sharedAccountingExpense';
import api from '@services/api';

interface SharedAccountingExpenseRequest {
  buildingAcronym?: string;
  month: number;
  year: number;
}

interface ProcessSharedAccountingExpenseRequest {
  buildingAcronym: string;
  period: string;
}

interface PreviewRequest {
  buildingAcronym: string;
  month: number;
  year: number;
}

interface DownloadOracleFile {
  url?: string;
}

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSharedAccountingExpenses: builder.query<Array<SharedAccountingExpense>, SharedAccountingExpenseRequest>({
      query: (input) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/list?month=${input.month}&year=${input.year}${
          input.buildingAcronym ? `&buildingAcronym=${input.buildingAcronym}` : ''
        }`,
        method: 'GET',
        validateStatus: (response) => response.status === 200,
      }),
      providesTags: ['Expense'],
    }),
    preview: builder.query<Array<PreviewEntry>, PreviewRequest>({
      query: (input: PreviewRequest) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/preview?buildingAcronym=${input.buildingAcronym}&month=${input.month}&year=${input.year}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200,
      }),
      providesTags: ['Expense'],
    }),
    downloadTxtFile: builder.mutation({
      queryFn: async ({ buildingAcronym, month, year }, baseApi, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/accounting-expense-sharing/shared-accounting-expenses/download?buildingAcronym=${buildingAcronym}&month=${month}&year=${year}`,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobTxt = url.createObjectURL(result.data as Blob);
        hiddenElement.href = blobTxt;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${buildingAcronym}_${month}_${year}.txt`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    downloadOracleFile: builder.query<DownloadOracleFile, PreviewRequest>({
      query: (input: PreviewRequest) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/process/download?buildingAcronym=${input.buildingAcronym}&month=${input.month}&year=${input.year}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200,
      }),
      providesTags: ['Expense'],
    }),
    procesSharedAccountingExpense: builder.mutation<void, ProcessSharedAccountingExpenseRequest>({
      query: (body) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/process`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const {
  useGetSharedAccountingExpensesQuery,
  usePreviewQuery,
  useProcesSharedAccountingExpenseMutation,
  useDownloadTxtFileMutation,
  useLazyDownloadOracleFileQuery,
} = expenseApi;
