import { Button } from '@mui/material';
import { CSSProperties } from 'react';

type TextButtonProps = {
  text: string;
  onClick: () => void;
  style?: CSSProperties;
};

function TextButton(props: TextButtonProps) {
  const { style, text, onClick } = props;
  return (
    <Button
      sx={{ textDecoration: 'none', fontSize: '14px', fontWeight: 'bold', ...style }}
      variant="tertiary"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default TextButton;
